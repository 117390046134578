import { observer } from "mobx-react";
import {
  AppShell,
  createStyles,
  rem,
  Header,
  Burger,
  useMantineTheme,
  Flex,
  Text,
  Button,
  Progress,
  LoadingOverlay,
  Loader,
  Image,
  Switch,
  Modal,
  Textarea,
  Slider,
  Checkbox,
  Menu,
  ActionIcon,
  Select,
} from "@mantine/core";
import User from "../User";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import Logo from "../../../components/Logo";
import { useStore } from "../../../hooks/use-store";
import useIsMobile from "../../../hooks/isMobile";
import { Model, PipelineData, PipelineParameters } from "./Pipeline";
import { useRequest } from "ahooks";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
import { PipelineItem } from "./PipelineItem";
import { FilesUpload, Widget } from "@uploadcare/react-widget";
import ImageObserved from "./Image";
import RelativeTime from "./RelativeTime";
import { Stage, Layer, Rect } from 'react-konva';
// import useImage from 'use-image';
import gerhard from './donkey.jpg';
import { BillingModal } from "../BillingModal";
import { Rectangle } from "./Resize";
import { topics } from "./images";
import { Helmet } from "react-helmet";

const useStyles = createStyles((theme) => ({
  lora: {
    'button': {
      // fontFamily: 'Lora !important',
      fontSize: '16px !important',
      height: '40px !important',
      width: '100% !important',
      padding: "8px !important",
      fontWeight: 'normal !important',
      textAlign: 'center !important',
      textDecoration: 'none !important',
      color: '#84cc15 !important'
    }
  },
  lora2: {
    'button': {
      // fontFamily: 'Lora !important',
      fontSize: '12px !important',
      height: '30px !important',
      width: 'max-content !important',
      padding: "8px !important",
      fontWeight: 'normal !important',
      textAlign: 'center !important',
      textDecoration: 'none !important',
      color: '#84cc15 !important'
    }
  },
  logo: {
    display: "flex",
    // "img": {
    //   height: "84px !important",
    //   width: '200px !important',
    //   objectFit: "cover !important",
    // },
  },
  card: {
    // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    border: '1px solid rgba(70, 70, 70, 1)'
  },

  cardTitle: {
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.2,
  },

  body: {
    padding: theme.spacing.md,
    background: '#000000',
  },

  wrapper: {
    height: "calc(100vh - 84px)",
    maxWidth: "100%",
    width: "100%",
    padding: '0 1rem',
  },

  title: {
    marginBottom: theme.spacing.sm,
    paddingLeft: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },

  center: {
    display: "flex",
    justifyContent: "center",
  },
  video: {
    margin: "0 auto",

    "> div": {
      position: "absolute!important" as any,
      transformOrigin: "left top",
      borderRadius: "8px",
      overflow: "hidden",
    },
  },
}));

enum MODE {
  GALLERY,
  MODELS,
  SELECTED_MODEL,
  SELECT_MODE,
  PIPELINES,
  DEPTH_MAP,
  ENLARGE,
  RELIGHT,
  UPSCALE,
  SELECT_ANGLE,
  CHANGE_BACKGROUND,
  SELECT_ANGLE_VIEWER,
}

const initialRectangles = [
  {
    x: 30,
    y: 30,
    width: 340,
    height: 340,
    fill: 'transparent',
    id: 'rect',
  },
];


const RELIGHT_PROMPS = {
  'sunny': "Sunny day",
  'susent': "Sunset",
  'night': "Night",
  'day': "Day",
  'cloudy': "Cloudy day",
  'sinrise': "Sunrise",
}

const Element = () => {

  const isMobile = useIsMobile()

  const theme = useMantineTheme();

  const { classes } = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [size, setSize] = useState({
    height: 952,
    width: 952,
  });
  const [opened, setOpened] = useState(false);
  const [big, setBig] = useState(false);
  const [variations, setVariatins] = useState(false);
  const [selectedModel, setSelectedModel] = useState<null | Model>(null);
  const [modelParams, setModelParams] = useState({
    prompt: '',
    aspect_ratio: "1:1",
    lora_scale: '1',
  });

  // const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const isDesktop = useMediaQuery(`(min-width: 500px)`);

  const {
    uiStore: { authStore },
  } = useStore();

  const [canvasBig, setCanvasBig] = useState(false);

  const [rectangles, setRectangles] = useState(initialRectangles);
  const [selectedId, selectShape] = useState('rect');
  const [latestRunId, setLatestRunId] = useState<null | { url: string, jobId: string }>(null);
  const [latestCustomModelRun, setLatestCustomModelRun] = useState<null | { jobId: string, srcset: string[] }>(null);

  const [trainModel, setTrainModel] = useState<{ open: boolean, step: number, images: string[], name: string, description: string, consent: boolean }>({
    open: false,
    step: 1,
    images: [
      // 'https://ucarecdn.com/0469de70-7b0b-457a-8181-d11180f653b3/', 'https://ucarecdn.com/5672fe60-e2fd-4475-9652-81aac4f18b39/',
      // 'https://ucarecdn.com/0469de70-7b0b-457a-8181-d11180f653b3/', 'https://ucarecdn.com/5672fe60-e2fd-4475-9652-81aac4f18b39/',
      // 'https://ucarecdn.com/0469de70-7b0b-457a-8181-d11180f653b3/', 'https://ucarecdn.com/5672fe60-e2fd-4475-9652-81aac4f18b39/',
      // 'https://ucarecdn.com/0469de70-7b0b-457a-8181-d11180f653b3/', 'https://ucarecdn.com/5672fe60-e2fd-4475-9652-81aac4f18b39/',
      // 'https://ucarecdn.com/0469de70-7b0b-457a-8181-d11180f653b3/', 'https://ucarecdn.com/5672fe60-e2fd-4475-9652-81aac4f18b39/',
      // 'https://ucarecdn.com/0469de70-7b0b-457a-8181-d11180f653b3/', 'https://ucarecdn.com/5672fe60-e2fd-4475-9652-81aac4f18b39/',

    ],
    name: '',
    consent: false,
    description: ''
  });

  const [latestRunIds, setLatestRunIds] = useState<{ url: string, jobId: string }[]>([
    // {
    //   url: 'test', jobId: 'string'
    // },
    // {
    //   url: 'test', jobId: 'string'
    // },
    // {
    //   url: 'test', jobId: 'string'
    // },
    // {
    //   url: 'test', jobId: 'string'
    // }
  ]);

  const stageRef = useRef(null);

  // const handleExport = async () => {
  //   const uri = stageRef.current.toDataURL();
  //   console.log(uri);
  //   // we also can save uri as file
  //   // but in the demo on Konva website it will not work
  //   // because of iframe restrictions
  //   // but feel free to use it in your apps:
  //   // downloadURI(uri, 'stage.png');
  // };


  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      // selectShape(null);
    }
  };

  const [pipelines, setPipelines] = useState<PipelineData[]>([]);
  const [models, setModels] = useState<Model[]>([]);
  const [mode, setMode] = useState(MODE.MODELS);


  const [enlargeParams, setEnlargeParams] = useState({
    url: '',
    left: 250,
    top: 0,
  });
  const [relightParams, setRelightParams] = useState({
    url: '',
    preset: 'sunny',
    mask: 1,
  });

  // const [imageSrc, setImageSrc] = useState<null | { url: string }>({ url: 'https://cdn.picshot.ai/eu/d1m64l88fvwigzp55mb88ocbrfd3d1.jpg' });
  const [imageSrc, setImageSrc] = useState<null | { url: string }>(null);
  const [backgroundSrc, setBacgroundSrc] = useState<null | { url: string }>(null);
  const [customLoading, setCustomLoading] = useState(false);
  const [billingModalOpen, setBillingModalOpen] = useState(false);

  const {
    dataStore: { appStore },
  } = useStore();

  const { loading, runAsync } = useRequest(
    async () =>
      appStore.getPipelines(),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => { },
    }
  );

  const { loading: fetchModelsLoading, runAsync: fetchModels } = useRequest(
    async () =>
      appStore.fetchModels(),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => { },
    }
  );
  const { loading: createPipelineLoading, runAsync: runCreatePipeline } = useRequest(
    async (pipelineParams: PipelineParameters) =>
      appStore.createPipeline({
        pipelineParams: pipelineParams
      }),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => {
        authStore.syncUser();
      },
    }
  );

  const syncBilling = useCallback(async (event: any) => {
    if (event.key === "sync") {
      authStore.syncUser();
      setTimeout(async () => {
        authStore.syncUser();
      }, 4000)
    }
  }, [authStore]);

  useEffect(() => {
    window.addEventListener("storage", syncBilling);

    return () => {
      window.removeEventListener("storage", syncBilling);
    };
  }, [syncBilling]);

  useEffect(() => {
    async function fetchData() {
      const res: {
        data: PipelineData
        status: 'pending' | 'completed' | 'ending' | 'failed';
      }[] = await runAsync();
      console.log(res);
      setPipelines(res.map((r) => ({
        ...r.data,
        status: r.status
      })));
    }

    async function fetchModelsData() {
      const res: Model[] = await fetchModels();
      console.log(res);
      setModels(res)
    }
    fetchData();
    fetchModelsData()
  }, [fetchModels, runAsync]);

  const { loading: runAsyncConvertBase64Loading, runAsync: runAsyncConvertBase64 } = useRequest(
    async (model: { url: string }) => appStore.convertImageBase64({
      url: model.url,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          return res.url
        }
      },
    }
  );
  const { loading: trainLoading, runAsync: train } = useRequest(
    async (model: { images: string[], name: string, description: string }) => appStore.train({
      images: model.images,
      name: model.name,
      description: model.description
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          authStore.syncUser();
          return res.url
        }
      },
    }
  );


  // useEffect(() => {
  //   const handler = async (event: any) => {
  //     try {
  //       let data
  //       try {
  //         data = JSON.parse(event.data)
  //       } catch (e) {
  //         return
  //       }

  //       if (data.name === 'GET_SCREENSHOT') {
  //         const url = await runAsyncConvertBase64({ url: data.image })
  //         // setDepthMapParams({
  //         //   ...depthMapParams,
  //         //   url3d: url.url,
  //         //   step: 2,
  //         // })
  //       }
  //       console.log(data)
  //     } catch (e) {
  //       console.log('portfolio unhandled', e)
  //     }
  //   }

  //   window.addEventListener('message', handler)

  //   // clean up
  //   return () => window.removeEventListener('message', handler)
  // }, [depthMapParams, runAsyncConvertBase64])

  const { loading: runAsyncConvertLoading, runAsync: runAsyncConvert } = useRequest(
    async (model: { url: string, removeBackground?: boolean }) => appStore.convertImage({
      url: model.url,
      removeBackground: model.removeBackground,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          return res.url
        }
      },
    }
  );



  if (isMobile) {
    return (
      <Flex h="100%" w={'100%'} align={'center'} justify={'center'} p={24}>
        <Text align="center">For an enhanced experience, please enter picshot on your desktop.</Text>
      </Flex>
    )
  }

  // // TODO remove after the wailist is removed
  // useEffect(() => {
  //   if (authStore.currentUser?.status.name === 'Inactive') {
  //     window.location.href = '/waitlist';
  //   }
  // }, [authStore]);

  // if (authStore.currentUser?.status.name === 'Inactive') {
  //   return null
  // }

  if (isMobile) {
    return (
      <Flex h="100%" w={'100%'} align={'center'} justify={'center'} p={24}>
        <Text align="center">For an enhanced experience, please enter picshot on your desktop.</Text>
      </Flex>
    )
  }

  const canvasSize = {
    small: {
      // width: 400,
      // height:  400 * size.height / size.width 
      width: size.height > size.width ? 400 * size.width / size.height : 400,
      height: size.height > size.width ? 400 : 400 * size.height / size.width
    },
    big: {
      width: size.height > size.width ? 2000 * size.width / size.height : 2000,
      height: size.height > size.width ? 2000 : 2000 * size.height / size.width
    }
  }
  // width={canvasBig ? 2000 : 400}
  // height={canvasBig ? 2000 : 400}
  const generateButtton = (credits: number, text?: string) => {
    return <Flex gap={12} align={'center'}>
      <Text>
        {text || 'Generate'}
      </Text>
      <Flex align={'center'} style={{
        borderRadius: '12px', background: 'rgb(101 156 16)',
        padding: '0 8px'
      }}>

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.98014 1.33337C4.30014 1.33337 1.31348 4.32004 1.31348 8.00004C1.31348 11.68 4.30014 14.6667 7.98014 14.6667C11.6601 14.6667 14.6468 11.68 14.6468 8.00004C14.6468 4.32004 11.6668 1.33337 7.98014 1.33337ZM10.4801 8.23337L8.00014 11.0534L7.70681 11.3867C7.30014 11.8467 6.96681 11.7267 6.96681 11.1067V8.46671H5.83348C5.32014 8.46671 5.18014 8.15337 5.52014 7.76671L8.00014 4.94671L8.29348 4.61337C8.70014 4.15337 9.03348 4.27337 9.03348 4.89337V7.53337H10.1668C10.6801 7.53337 10.8201 7.84671 10.4801 8.23337Z" fill="white" />
        </svg>
        <Text color="white">{credits}</Text>
      </Flex >
    </Flex>
  }

  const renderMenu = (size: string, src: string) => {
    return <Flex
      justify={'space-between'}
      align={'center'}>
      <Text fz={12}> </Text>
      <Menu shadow="md">
        <Menu.Target>

          <ActionIcon>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#BDBDBD" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1.33301 8.58679V7.41345C1.33301 6.72012 1.89967 6.14679 2.59967 6.14679C3.80634 6.14679 4.29967 5.29345 3.69301 4.24679C3.34634 3.64679 3.55301 2.86679 4.15967 2.52012L5.31301 1.86012C5.83967 1.54679 6.51967 1.73345 6.83301 2.26012L6.90634 2.38679C7.50634 3.43345 8.49301 3.43345 9.09967 2.38679L9.17301 2.26012C9.48634 1.73345 10.1663 1.54679 10.693 1.86012L11.8463 2.52012C12.453 2.86679 12.6597 3.64679 12.313 4.24679C11.7063 5.29345 12.1997 6.14679 13.4063 6.14679C14.0997 6.14679 14.673 6.71345 14.673 7.41345V8.58679C14.673 9.28012 14.1063 9.85345 13.4063 9.85345C12.1997 9.85345 11.7063 10.7068 12.313 11.7535C12.6597 12.3601 12.453 13.1335 11.8463 13.4801L10.693 14.1401C10.1663 14.4535 9.48634 14.2668 9.17301 13.7401L9.09967 13.6135C8.49967 12.5668 7.51301 12.5668 6.90634 13.6135L6.83301 13.7401C6.51967 14.2668 5.83967 14.4535 5.31301 14.1401L4.15967 13.4801C3.55301 13.1335 3.34634 12.3535 3.69301 11.7535C4.29967 10.7068 3.80634 9.85345 2.59967 9.85345C1.89967 9.85345 1.33301 9.28012 1.33301 8.58679Z" stroke="#BDBDBD" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </ActionIcon>

        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Actions</Menu.Label>
          <Menu.Item icon={
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 6V2H10" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2 10V14H6" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 2L9 7" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7 9L2 14" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          }

            onClick={() => {
              setImageSrc({
                url: src,
              });
              setMode(MODE.UPSCALE)
            }}

          // onClick={() => {
          //     model.create({
          //         provider: 'replicate',
          //         type: 'upscale',
          //         inputSrc: src,
          //         outputSrc: '',
          //         credits: 5,
          //     })
          // }}
          >
            Upscale
          </Menu.Item>
          <Menu.Item icon={
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.33301 6.65325V5.99992C1.33301 2.66659 2.66634 1.33325 5.99967 1.33325H9.99967C13.333 1.33325 14.6663 2.66659 14.6663 5.99992V9.99992C14.6663 13.3333 13.333 14.6666 9.99967 14.6666H9.33301" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.66699 7.33324L12.007 3.98657H9.33366" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0068 3.98657V6.65991" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.33301 10.7667V12.5667C7.33301 14.0667 6.73301 14.6667 5.23301 14.6667H3.43301C1.93301 14.6667 1.33301 14.0667 1.33301 12.5667V10.7667C1.33301 9.26675 1.93301 8.66675 3.43301 8.66675H5.23301C6.73301 8.66675 7.33301 9.26675 7.33301 10.7667Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          }

            onClick={() => {
              setEnlargeParams({
                ...enlargeParams,
                url: src
              })
              setMode(MODE.ENLARGE)
            }}
          >
            Enlarge
          </Menu.Item>
          <Menu.Item
            component={"a"}
            href={src}
            target={"_blank"}
            download='test.png'
            icon={
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 7.33325V11.3333L7.33333 9.99992" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.00033 11.3333L4.66699 10" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.6663 6.66659V9.99992C14.6663 13.3333 13.333 14.6666 9.99967 14.6666H5.99967C2.66634 14.6666 1.33301 13.3333 1.33301 9.99992V5.99992C1.33301 2.66659 2.66634 1.33325 5.99967 1.33325H9.33301" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.6663 6.66658H11.9997C9.99967 6.66658 9.33301 5.99992 9.33301 3.99992V1.33325L14.6663 6.66658Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            }
          >
            Download
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Flex>

  }
  return (
    <AppShell
      padding={0}
      header={
        isDesktop ? (
          <Header height={{ base: '84px' }} p="md" bg={'#000000'} withBorder={false}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
              {
                mode === MODE.GALLERY ? (
                  <div className={classes.logo} onClick={() => setMode(MODE.MODELS)}>
                    <Logo size={200} />
                  </div>) :
                  (<div onClick={() => setMode(MODE.MODELS)}>

                    <Image width={'140px'} src={gerhard}></Image></div>)
              }


              <Flex gap={12}>
              <Button
                  h="53.8px"
                  bg="#000000"
                  c="#fe3d00"
                  fw={500}
                  variant="light"
                  color="#fe3d00"

                  onClick={() => setMode(MODE.PIPELINES)}>
                  <Flex direction={'column'} justify={'center'} align={'center'}>
                    <Text color="white">Gallery</Text>
                  </Flex>
                </Button>
                <Button
                  h="53.8px"
                  bg="#fe3d00"
                  c="black"
                  fw={700}
                  variant="light"
                  color="black"

                  onClick={() => setMode(MODE.MODELS)}>
                  <Flex direction={'column'} justify={'center'} align={'center'}>
                    {/* <Text color="white">use Gerhard</Text> */}
                    <Text fz={12} color="white" italic fw={300}>Train your own Al with your artwork</Text>
                  </Flex>
                </Button>

                <Button
                  h="53.8px"
                  bg="#242b1e"
                  c="#b4e45f"
                  fw={700}
                  variant="light"
                  color="#b4e45f"

                  onClick={() => {
                    setMode(MODE.GALLERY)
                  }}>
                  <Flex direction={'column'} justify={'center'} align={'center'}>
                    {/* <Text color="white">use Picshot</Text> */}
                    <Text fz={12} color="white" italic fw={300}>Product background generator</Text>
                  </Flex>
                </Button>
                <User
                  buyCredits={() => setBillingModalOpen(true)}
                  billingHistory={async () => {
                    const res = await appStore.getPortalLink({
                    })
                    if (res.url) {

                      let a = document.createElement('a');
                      a.target = '_blank';
                      a.href = res.url;
                      a.click();
                    } else {
                    }
                  }} />
              </Flex>
            </div>
          </Header>
        ) :
          (
            <Header height={{ base: 50, md: 70 }} p="md" bg={'#000000'}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>

                <div className={classes.logo}>
                  <Logo size={40} />
                </div>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                />
              </div>
            </Header>
          )
      }


      styles={(theme) => ({
        main: {
          backgroundColor:
            '#000000'
        },
      })}
    >
      <Flex className={classes.wrapper} gap={12}>
        <Helmet>
          <script
            id="helpspace-widget-script"
            data-auto-init
            data-token="ipUPRazE10l8M0mHt8VRS4itUa6S1E8ncHz2MNgu"
            data-client-id="aa65cb29f0594afdbfb2b9bbc0873a38"
            data-widget-id="e0d49b4d-ffda-4102-8baf-4332c3b1c675"
            src="https://cdn.helpspace.com/widget/widget-v1.js"
            async>
          </script>

        </Helmet>

        <Modal

          styles={{
            content: {
              '> div': {

                minHeight: '100vh',
              }
            },
            header: {

              background: '#000000 !important'
            },
            body: {
              minHeight: '100vh',
              background: '#000000'
            }
          }}
          opened={trainModel.open}
          onClose={() => {
            setTrainModel({
              open: false,
              step: 1,
              images: [

              ],
              name: '',
              consent: false,
              description: ''
            })
          }} title={'Train your own model'} fullScreen>
          <Flex justify={'center'}>
            <Flex maw={'800px'}>

              {
                trainModel.step === 1 ? <Flex direction={'column'} justify='center' align="center" gap={24}>
                  <Text color="white">Step 1/2</Text>
                  <Text color="white">You need at least 10 images in a consistent style to train your model. They must be at least 512 px in the highest resolution possible. Upload as many images as you can in the set style. While 10 images are the minimum required to train a model, the more you upload, the better the model will be.</Text>
                  {trainModel.images.length > 0 ?
                    <Button
                      styles={{
                        root: {
                          color: 'rgba(230, 230, 230, 1)',
                          height: rem(36),

                          fontSize: '14px',
                          background: '#84cc15 !important',
                          border: 'none',
                          position: 'relative',
                        }
                      }}
                      onClick={() => {
                        setTrainModel({
                          ...trainModel,
                          step: 2
                        })
                      }}>
                      Next step
                    </Button> : null}
                  {
                    trainModel.images.length > 0 ?
                      <Flex gap={12} justify={'center'} wrap={'wrap'}>
                        {trainModel.images.map((itm, index) => {
                          return <Flex
                            key={index}
                            h="100px"
                            w={'100px'}
                            onClick={() => {
                              // setBacgroundSrc({
                              //   url: itm
                              // })
                            }}
                          >
                            <ImageObserved
                              width={'100px'}
                              height={'100px'}
                              src={itm}
                            />
                          </Flex>
                        })}
                      </Flex> :
                      <Widget
                        imagesOnly
                        multipleMax={50}
                        multipleMin={10}
                        localeTranslations={{
                          buttons: {
                            choose: {
                              images: {
                                one: 'Select images'
                              },
                            },
                          },
                        }}
                        clearable={true}
                        multiple={true}
                        onFileSelect={async (res) => {
                          const imgs: string[] = [];
                          if (res) {
                            const files = (res as FilesUpload).files();
                            for (const file of files) {
                              // @ts-ignore
                              const img = await file.promise();
                              imgs.push(img.cdnUrl);
                            }
                          }
                          setTrainModel({
                            ...trainModel,
                            step: 1,
                            images: imgs
                          })
                          console.log(imgs)
                        }}
                        tabs={"file camera url"}
                        publicKey="35626f59762b63c4244c"
                        previewStep={true}
                        crop="free, 16:9, 4:3, 5:4, 1:1"
                      />
                  }

                </Flex> : null
              }
              {
                trainModel.step === 2 ? <Flex direction={'column'} justify='center' align="center" gap={24}>
                  <Text color="white">Step 2/2</Text>
                  <Text color="white">Final step: Name your model & start training.</Text>
                  <Textarea
                    w="300px"
                    minRows={1}
                    size="sm"
                    label=""
                    value={trainModel.name}
                    placeholder=""
                    onChange={(e) => {
                      setTrainModel({
                        ...trainModel,
                        name: e.currentTarget.value
                      })

                    }}
                  />


                  <Select
                    w="300px"
                    size="sm"
                    description=""
                    label="Select type of your images"
                    placeholder="Pick value"
                    value={trainModel.description}
                    onChange={(value) => {
                      setTrainModel({
                        ...trainModel,
                        description: value!
                      })

                    }}
                    mb={10}
                    data={[
                      { value: 'photograph', label: 'Photograph' },
                      { value: 'illustration', label: 'Illustration' },
                      { value: '3D render', label: '3D Render' },
                      { value: 'pixel art', label: 'Pixel Art' },
                      { value: 'abstract', label: 'Abstract' },
                    ]}
                  />


                  <Checkbox
                    mt={10}
                    mb={10}
                    w="300px"
                    color="dimmed"
                    size={'sm'}
                    labelPosition="left"
                    styles={{
                      body: {
                        alignItems: 'center',
                      },
                      label: {
                        fontSize: 12,
                      },
                      input: {
                        borderRadius: 2,
                        borderColor: '#464646',
                        backgroundColor: 'transparent',
                      }
                    }}
                    label={'I confirm that I own the rights to all images used for training this AI model'}
                    checked={trainModel.consent}
                    onChange={(e) => {
                      setTrainModel({
                        ...trainModel,
                        consent: e.currentTarget.checked
                      })
                    }}
                  />

                  {trainModel.images.length > 0 ?

                    <Button
                      loading={trainLoading}
                      mb={20}
                      disabled={!trainModel.consent || trainModel.name.length === 0}
                      styles={{
                        root: {
                          color: 'rgba(230, 230, 230, 1)',
                          height: rem(36),

                          fontSize: '14px',
                          background: '#84cc15 !important',
                          border: 'none',
                          position: 'relative',
                        }
                      }}
                      variant="default"
                      onClick={async () => {

                        if (authStore.currentUser?.credits < 60) {
                          NOTIFICATION_SERVICE.errorNotification(
                            "Not enough credits"
                          );
                          setBillingModalOpen(true)
                        } else {
                          await train({
                            images: trainModel.images,
                            name: trainModel.name,
                            description: trainModel.description
                          })

                          NOTIFICATION_SERVICE.successNotification('training in progress. It can take up to 20 minutes. You will be notified by email once it’s ready. Feel free to start new trainings while waiting')

                          const r: Model[] = await fetchModels();
                          console.log(r);
                          setModels(r)

                          setTrainModel({
                            open: false,
                            step: 1,
                            images: [

                            ],
                            name: '',
                            consent: false,
                            description: '',
                          })
                        }
                      }}
                    >
                      Begin training {generateButtton(60, ' ')}
                    </Button>
                    : null}
                  {
                    trainModel.images.length > 0 ?
                      <Flex gap={12} justify={'center'} wrap={'wrap'}>
                        {trainModel.images.map((itm, index) => {
                          return <Flex
                            key={index}
                            h="100px"
                            w={'100px'}
                            onClick={() => {
                              // setBacgroundSrc({
                              //   url: itm
                              // })
                            }}
                          >
                            <ImageObserved
                              width={'100px'}
                              height={'100px'}
                              src={itm}
                            />
                          </Flex>
                        })}
                      </Flex> :
                      null
                  }

                </Flex> : null
              }
            </Flex>
          </Flex>
        </Modal>
        <BillingModal opened={billingModalOpen} close={() => setBillingModalOpen(false)} ></BillingModal>
        <LoadingOverlay visible={loading || customLoading || runAsyncConvertBase64Loading || runAsyncConvertLoading || createPipelineLoading || fetchModelsLoading} loaderProps={{ children: <Flex gap={8}><Loader color="orange" /><Text>Generating...</Text></Flex> }} />
        <Flex w={'100%'} pos="relative" bg="#000000" style={{ borderRadius: '8px', overflowY: 'scroll', height: '100%' }}
          // p={'0.8rem'} 
          direction={'column'}>

          {
            mode === MODE.GALLERY && (
              <Flex style={{ height: '100%' }}>
                <Flex w={'30%'} pr={'20px'} direction={'column'} style={{ height: '100vh', overflow: 'scroll' }}>
                  <Flex justify={'space-between'} align={'center'} w={'100%'} mb={20}>
                    <Text fz={13} color="white"
                      style={{ fontFamily: 'Lora' }}>Choose background from gallery below
                    </Text>
                    <Flex className={classes.lora2} justify={'center'} >
                      {
                        !backgroundSrc ? <Widget
                          imagesOnly
                          localeTranslations={{
                            buttons: {
                              choose: {
                                images: {
                                  one: 'or upload custom'
                                },
                              },
                            },
                          }}
                          clearable={false}
                          // @ts-ignore
                          onChange={async (info: {
                            cdnUrl: string, originalImageInfo: {
                              width: number
                              height: number
                            }
                          }) => {
                            setCustomLoading(true)
                            const res = await runAsyncConvert({ url: info.cdnUrl, removeBackground: false })
                            setCustomLoading(false)
                            setBacgroundSrc({
                              url: res.url,
                            })
                          }}
                          tabs={"file camera url"}
                          publicKey="35626f59762b63c4244c"
                          previewStep={true}
                          crop="free, 16:9, 4:3, 5:4, 1:1"
                        /> : null
                      }

                    </Flex>
                  </Flex>

                  {/* <Flex justify={'space-between'} align={'center'} w={'100%'} mb={20} gap={20}>
                    <Text
                      fz={16}
                      color={size.width === 952 && size.height === 952 ? '#84cc15' : 'white'}
                      onClick={() => setSize({ width: 952, height: 952 })}
                      style={{ fontFamily: 'Lora' }}
                    >
                      Square
                    </Text>
                    <Text
                      fz={16}
                      color={size.width === 536 && size.height === 952 ? '#84cc15' : 'white'}
                      onClick={() => setSize({ width: 536, height: 952 })}
                      style={{ fontFamily: 'Lora' }}
                    >
                      Square
                    </Text>
                    <Text
                      fz={16}
                      color={size.width === 952 && size.height === 536 ? '#84cc15' : 'white'}
                      onClick={() => setSize({ width: 952, height: 536 })}
                      style={{ fontFamily: 'Lora' }}
                    >
                      Landscape
                    </Text>
                  </Flex> */}

                  <Flex direction={'column'} gap={8}>
                    {topics.map((item, index) => {
                      return <Flex direction={'column'}>
                        <Text color="white" fz={14} mb={6} style={{ fontFamily: 'Lora' }}>{item.topicName}</Text>
                        <Flex gap={16} w={'100%'} bg="#000000" style={{ overflowX: 'scroll', overflowY: 'hidden', paddingBottom: '12px', height: '140px' }}>
                          {item.images.map((itm, index) => {
                            return <Flex
                              key={index}
                              h="140px"
                              w={'140px'}
                              onClick={() => {
                                setBacgroundSrc({
                                  url: itm
                                })
                              }}
                            >
                              <ImageObserved
                                width={'140px'}
                                height={'140px'}
                                src={itm}
                              />
                            </Flex>
                          })}
                        </Flex>
                      </Flex>
                    })}
                  </Flex>
                </Flex>
                <Flex w={'70%'} align={'center'} style={{ height: '100%', overflow: 'scroll' }}>
                  <Flex w="100%" gap={12} direction={'column'} align={'center'} style={{ overflow: 'scroll' }}>
                    <Flex gap={12}>
                      <Flex direction={'column'} gap={6}>
                        <Text color="#84cc15" fz={12}>Your product here</Text>
                        <Flex
                          w={`${canvasBig ? canvasSize.big.width : canvasSize.small.width}px`}
                          h={`${canvasBig ? canvasSize.big.height : canvasSize.small.height}px`}
                          style={{
                            border: '2px solid #84cc15',
                            position: 'relative', overflow: 'hidden'
                          }}>
                          <Flex w={'100%'} h={"100%"} style={{ position: 'absolute', top: 0, left: 0, opacity: 0.5 }}>
                            {
                              backgroundSrc?.url ? <Image

                                width={'100%'}
                                height={'100%'}
                                bg="#000000"
                                styles={{
                                  root: {
                                    display: 'flex',
                                    alignItems: 'center',
                                  },
                                  figure: {
                                    height: '100%',
                                  },
                                  imageWrapper: {
                                    height: '100%'
                                  },
                                  image: {
                                    height: '100%'
                                    // borderRadius: '50%',
                                  }
                                }}
                                src={backgroundSrc?.url}
                                alt=""
                                // withPlaceholder
                                fit="cover"
                              /> : null
                            }

                          </Flex>

                          {
                            imageSrc?.url ? (
                              <Stage
                                width={canvasBig ? canvasSize.big.width : canvasSize.small.width}
                                height={canvasBig ? canvasSize.big.height : canvasSize.small.height}
                                onMouseDown={checkDeselect}
                                onTouchStart={checkDeselect}
                                style={{
                                  background: '#000000',
                                }}
                                ref={stageRef}
                              >
                                <Layer>
                                  {
                                    canvasBig && <Rect width={canvasSize.big.width} height={canvasSize.big.height} x={0} y={0} listening={false} fill={'#ffffff'}></Rect>
                                  }

                                  {rectangles.map((rect, i) => {
                                    if (imageSrc?.url) {
                                      return (
                                        <Rectangle
                                          key={i}
                                          big={canvasBig}
                                          shapeProps={{
                                            ...rect,
                                            height: canvasBig ? rect.height * 5 : rect.height,
                                            width: canvasBig ? rect.width * 5 : rect.width,
                                            x: canvasBig ? rect.x * 5 : rect.x,
                                            y: canvasBig ? rect.y * 5 : rect.y,
                                          }}
                                          image={imageSrc?.url}
                                          isSelected={rect.id === selectedId}
                                          onSelect={() => {
                                            // selectShape(rect.id);
                                          }}
                                          onChange={(newAttrs: any) => {
                                            const rects = rectangles.slice();
                                            if (rects[i].height * 0.7 < newAttrs.height || rects[i].width * 0.7 < newAttrs.width) {
                                              NOTIFICATION_SERVICE.errorNotification('Resize may affect the quality of the result')
                                            }
                                            rects[i] = newAttrs;
                                            setRectangles(rects);
                                            console.log(rects)
                                          }}
                                        />
                                      );
                                    } else {
                                      return null
                                    }
                                  })}

                                </Layer>
                              </Stage>
                            ) :
                              <Flex gap={2} justify={'center'} w={'100%'} mt={20} mb={20} align={'center'} style={{ zIndex: 10 }}>
                                <Flex w="100%" className={classes.lora} justify={'center'} >
                                  <Widget
                                    imagesOnly
                                    localeTranslations={{
                                      buttons: {
                                        choose: {
                                          images: {
                                            one: 'upload product photo'
                                          },
                                        },
                                      },
                                    }}
                                    clearable={false}
                                    // @ts-ignore
                                    onChange={async (info: {
                                      crop?: {
                                        width: number
                                        height: number
                                      },
                                      cdnUrl: string,
                                      originalImageInfo: {
                                        width: number
                                        height: number
                                      }
                                    }) => {
                                      //   const id = spaceStore.selected?.id
                                      setCustomLoading(true)
                                      console.log(info)
                                      // info.originalImageInfo.width > 
                                      const res = await runAsyncConvert({ url: info.cdnUrl, removeBackground: true })

                                      const width = info.crop?.width || info.originalImageInfo.width
                                      const height = info.crop?.height || info.originalImageInfo.height

                                      const ratio = width > height ? height / width : width / height
                                      // maximum height and width is 400
                                      setRectangles([{
                                        ...initialRectangles[0],
                                        width: width > height ? 390 : 390 * ratio,
                                        height: width > height ? 390 * ratio : 390,
                                        x: 3,
                                        y: 3,
                                      }])
                                      setCustomLoading(false)
                                      setImageSrc({
                                        url: res.url,
                                      })
                                    }}
                                    tabs={"file camera url"}
                                    publicKey="35626f59762b63c4244c"
                                    previewStep={true}
                                    crop="free, 16:9, 4:3, 5:4, 1:1"
                                  />
                                </Flex>

                              </Flex>
                          }

                          {/* <App image={'/android-chrome-512x512.png'} /> */}
                        </Flex>

                      </Flex>

                      <Flex direction={'column'} gap={6}>
                        <Text color="#84cc15" fz={12}>Generation result here</Text>
                        <Flex

                          w={canvasSize.small.width}
                          h={canvasSize.small.height}
                          style={{ border: '2px solid #84cc15' }}>
                          {
                            latestRunId ? (
                              latestRunId?.url ? (
                                <ImageObserved
                                  width={canvasSize.small.width + 'px'}
                                  height={canvasSize.small.height + 'px'}
                                  src={latestRunId?.url}
                                />
                              ) : (
                                <Flex w="100%" h="100%" p={32} align={'center'} justify={'center'} direction={'column'}>

                                  <Text color="#84cc15" fz={12}>Will be ready in 1 minute or less</Text>
                                  <Progress radius="lg" size="lg" color="#84cc15" value={100} striped animate={true} w={'100%'} />
                                </Flex>
                              )
                            ) : null
                          }
                        </Flex>
                      </Flex>
                    </Flex>

                    {
                      latestRunIds.length > 0 ? <Flex gap={12}>
                        {
                          latestRunIds.map((item, index) => {
                            return <Flex
                              w={`140px`}
                              h={`140px`}
                              style={{
                                border: '2px solid #84cc15',
                                position: 'relative', overflow: 'hidden'
                              }}>

                              {
                                item.url ? <Image

                                  width={'140px'}
                                  height={'140px'}
                                  bg="#000000"
                                  styles={{
                                    image: {
                                      height: '100%'
                                      // borderRadius: '50%',
                                    }
                                  }}
                                  src={item.url}
                                  alt=""
                                  // withPlaceholder
                                  fit="cover"
                                /> : <Flex w="100%" h="100%" p={6} align={'center'} justify={'center'} direction={'column'}>

                                  <Text color="#84cc15" fz={12}>Generating</Text>
                                  <Progress radius="lg" size="lg" color="#84cc15" value={100} striped animate={true} w={'100%'} />
                                </Flex>
                              }

                            </Flex>
                          })
                        }

                      </Flex> : null
                    }
                    <Flex justify={'space-between'} w="100%" style={{ maxWidth: '812px' }}>
                      <Flex>
                        {
                          imageSrc?.url ? (
                            <Flex justify={'space-between'} align={'center'} w="100%" pl={12} pr={12}>
                              <Flex direction={'column'}>
                                <Flex w="100px">
                                  <ImageObserved
                                    width={'100px'}
                                    height={'100px'}
                                    src={imageSrc.url}
                                  />
                                </Flex>
                                <Text fz={14}
                                  color="white"
                                  align="center"
                                  style={{ fontFamily: 'Lora' }}>
                                  Product image
                                </Text>
                                <Button
                                  styles={{
                                    root: {
                                      color: '#84cc15',
                                      height: rem(28),

                                      fontSize: '10px',
                                      background: 'transparent !important',
                                      border: 'none',
                                      position: 'relative',
                                    }
                                  }}
                                  variant="default"
                                  onClick={async () => {
                                    setImageSrc(null)
                                  }}
                                >
                                  Delete
                                </Button>
                              </Flex>

                            </Flex>
                          ) : (
                            null
                          )
                        }
                        {
                          backgroundSrc ? (
                            <Flex justify={'space-between'} align={'center'} w="100%" pl={12} pr={12}>
                              <Flex direction={'column'}>
                                <Flex w="100px">
                                  <ImageObserved
                                    width={'100px'}
                                    height={'100px'}
                                    src={backgroundSrc.url}
                                  />
                                </Flex>
                                <Text fz={14} color="white"
                                  align="center"
                                  style={{ fontFamily: 'Lora' }}>Photo mood
                                </Text>
                                <Button
                                  styles={{
                                    root: {
                                      color: '#84cc15',
                                      height: rem(28),

                                      fontSize: '10px',
                                      background: 'transparent !important',
                                      border: 'none',
                                      position: 'relative',
                                    }
                                  }}
                                  variant="default"
                                  onClick={async () => {
                                    setBacgroundSrc(null)
                                  }}
                                >
                                  Delete
                                </Button>

                              </Flex>

                            </Flex>
                          ) : <Flex className={classes.lora2} justify={'center'} >
                            <Widget
                              imagesOnly
                              localeTranslations={{
                                buttons: {
                                  choose: {
                                    images: {
                                      one: 'Upload custom background'
                                    },
                                  },
                                },
                              }}
                              clearable={false}
                              // @ts-ignore
                              onChange={async (info: {
                                cdnUrl: string, originalImageInfo: {
                                  width: number
                                  height: number
                                }
                              }) => {
                                setCustomLoading(true)
                                const res = await runAsyncConvert({ url: info.cdnUrl, removeBackground: false })
                                setCustomLoading(false)
                                setBacgroundSrc({
                                  url: res.url,
                                })
                              }}
                              tabs={"file camera url"}
                              publicKey="35626f59762b63c4244c"
                              previewStep={true}
                              crop="free, 16:9, 4:3, 5:4, 1:1"
                            />
                          </Flex>
                        }
                      </Flex>
                      <Flex gap={12} mt={32} justify={'end'}>
                        <Flex direction={'column'} gap={12} align={'flex-end'}>
                          <Button
                            disabled={!imageSrc || !backgroundSrc}
                            styles={{
                              root: {
                                color: 'black',
                                height: rem(46),

                                fontSize: '18px',
                                background: '#84cc15 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={async () => {
                              const pp = []


                              const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
                              setCanvasBig(true)
                              selectShape(null)
                              await sleep(1)
                              const productImage = stageRef.current.toDataURL();
                              setCanvasBig(false)
                              selectShape('rect');
                              await sleep(1)
                              const url = await runAsyncConvertBase64({ url: productImage })

                              const pipeline: PipelineParameters = {
                                provider: 'comfy',
                                type: 'changeBackground3',
                                prompt: 'product',
                                negativePrompt: 'bad lines',
                                inputSrc: url.url,
                                backgroundSrc: backgroundSrc!.url,
                                gradientSide: 'center',
                                width: size.width,
                                height: size.height,
                                srcset: [],
                                credits: 2,
                              }
                              const res = await runCreatePipeline(pipeline);

                              if (res.error) {
                                NOTIFICATION_SERVICE.errorNotification(
                                  "Not enough credits"
                                );
                                setBillingModalOpen(true)
                              } else {
                                pp.push({
                                  ...res.data,
                                  status: res.status
                                })

                                setLatestRunId({
                                  url: '',
                                  jobId: res.data.jobId
                                })

                                if (variations) {
                                  const latestRunIdsNew = []

                                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                  for (const iteration of [1, 2, 3, 4]) {
                                    const pipeline1: PipelineParameters = {
                                      provider: 'comfy',
                                      type: 'changeBackground',
                                      prompt: 'product',
                                      negativePrompt: 'bad lines',
                                      inputSrc: url.url,
                                      backgroundSrc: backgroundSrc!.url,
                                      gradientSide: 'center',
                                      width: size.width,
                                      height: size.height,
                                      srcset: [],
                                      credits: 2,
                                    }
                                    const res2 = await runCreatePipeline(pipeline1);

                                    if (res2.error) {
                                      NOTIFICATION_SERVICE.errorNotification(
                                        "Not enough credits"
                                      );
                                      setBillingModalOpen(true)
                                    } else {
                                      pp.push({
                                        ...res2.data,
                                        status: res2.status
                                      })

                                      latestRunIdsNew.push({
                                        url: '',
                                        jobId: res2.data.jobId
                                      })
                                    }
                                  }
                                  setLatestRunIds(latestRunIdsNew)
                                } else {
                                  setLatestRunIds([])
                                }
                                setPipelines([...pp, ...pipelines])
                              }
                            }}
                          >
                            {generateButtton(variations ? 10 : 2)}
                          </Button>

                          <Switch
                            size="xs"
                            checked={variations}
                            onChange={() => setVariatins(!variations)}
                            color="lime"
                            label="Variations"
                          />
                        </Flex>


                        {/* {
                          authStore.currentUser?.email.includes('lado@ladoalexi.com') || authStore.currentUser?.email.includes('sergey@klykn.com') ? <div>

                            <Button
                              disabled={!imageSrc || !backgroundSrc}
                              styles={{
                                root: {
                                  color: 'black',
                                  height: rem(46),

                                  fontSize: '18px',
                                  background: '#84cc15 !important',
                                  border: 'none',
                                  position: 'relative',
                                }
                              }}
                              variant="default"
                              onClick={async () => {
                                const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
                                setCanvasBig(true)
                                selectShape(null)
                                await sleep(1)
                                const productImage = stageRef.current.toDataURL();
                                setCanvasBig(false)
                                selectShape('rect');
                                await sleep(1)
                                const url = await runAsyncConvertBase64({ url: productImage })

                                const pipeline: PipelineParameters = {
                                  provider: 'comfy',
                                  type: 'changeBackground',
                                  prompt: 'product',
                                  negativePrompt: 'bad lines',
                                  inputSrc: url.url,
                                  backgroundSrc: backgroundSrc!.url,
                                  gradientSide: 'center',
                                  width: size.width,
                                  height: size.height,
                                  srcset: [],
                                  credits: 5,
                                }
                                const res = await runCreatePipeline(pipeline);

                                if (res.error) {
                                  NOTIFICATION_SERVICE.errorNotification(
                                    "Not enough credits"
                                  );
                                  setBillingModalOpen(true)
                                } else {
                                  setPipelines([{
                                    ...res.data,
                                    status: res.status
                                  }, ...pipelines])

                                  setLatestRunId({
                                    url: '',
                                    jobId: res.data.jobId
                                  })
                                }
                              }}
                            >
                              1
                            </Button>
                            <Button
                              disabled={!imageSrc || !backgroundSrc}
                              styles={{
                                root: {
                                  color: 'black',
                                  height: rem(46),

                                  fontSize: '18px',
                                  background: '#84cc15 !important',
                                  border: 'none',
                                  position: 'relative',
                                }
                              }}
                              variant="default"
                              onClick={async () => {
                                const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
                                setCanvasBig(true)
                                selectShape(null)
                                await sleep(1)
                                const productImage = stageRef.current.toDataURL();
                                setCanvasBig(false)
                                selectShape('rect');
                                await sleep(1)
                                const url = await runAsyncConvertBase64({ url: productImage })

                                const pipeline: PipelineParameters = {
                                  provider: 'comfy',
                                  type: 'changeBackground4',
                                  prompt: 'product',
                                  negativePrompt: 'bad lines',
                                  inputSrc: url.url,
                                  backgroundSrc: backgroundSrc!.url,
                                  gradientSide: 'center',
                                  width: size.width,
                                  height: size.height,
                                  srcset: [],
                                  credits: 5,
                                }
                                const res = await runCreatePipeline(pipeline);

                                if (res.error) {
                                  NOTIFICATION_SERVICE.errorNotification(
                                    "Not enough credits"
                                  );
                                  setBillingModalOpen(true)
                                } else {
                                  setPipelines([{
                                    ...res.data,
                                    status: res.status
                                  }, ...pipelines])

                                  setLatestRunId({
                                    url: '',
                                    jobId: res.data.jobId
                                  })
                                }
                              }}
                            >
                              3
                            </Button>
                          </div> : null
                        } */}

                      </Flex>
                    </Flex>

                  </Flex>
                </Flex>
              </Flex>

            )
          }
          {
            mode === MODE.MODELS && (
              <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32} direction={'column'}>
                <Flex w={'100%'} direction={'column'} gap={12}>
                  <Text color={'white'} fz={20}>Trained AI Models</Text>
                  <Flex gap={12} wrap={'wrap'}>
                    <Flex
                      // bg={
                      //   '#242b1e'
                      // }
                      p={12}
                      justify={'space-between'}
                      onClick={() => {
                        setTrainModel({
                          open: true,
                          step: 1,
                          images: [

                          ],
                          name: '',
                          consent: false,
                          description: ''
                        })
                      }} h="200px" w="200px" align='center' direction={'column'} gap={12} style={{ width: '230px', cursor: 'pointer', border: '2px solid #51594a', borderRadius: '2px' }}>
                      <Text
                        color="#fe3d00"
                        align='center'
                        lh={'20px'}
                        mt={30}
                        fz={100}
                      >+</Text>

                      <Text   color="#fe3d00">Train new</Text>
                    </Flex>
                    {
                      models
                        .map((model, index) => {
                          return <Flex w={'100%'}
                            key={index}
                            direction={'column'}
                            gap={12}
                            justify={'space-between'}
                            bg={
                              '#191918'
                            }
                            style={{
                              border: '2px solid #50594a', borderRadius: '2px',
                              width: '230px',
                              height: '200px',
                            }} p={12} align={'center'}>
                            <Text color={'white'} fz={16}>{model.data.name}</Text>
                            <Flex justify={'space-between'}>
                              {(model.data?.images || [])
                                .slice(0, 3)
                                .map((img, index) => {
                                  return <Flex
                                    key={index}
                                    h="auto"
                                    p={2}
                                    w={'33.3%'}
                                    onClick={() => {
                                      setBacgroundSrc({
                                        url: img
                                      })
                                    }}
                                  >
                                    <ImageObserved
                                      width={'100%'}
                                      height={'auto'}
                                      src={img}
                                    />
                                  </Flex>
                                })
                              }
                            </Flex>
                            <Flex gap={6}>
                              {
                                model.status ===
                                  'completed' ?
                                  <Button
                                    styles={{
                                      root: {
                                        color: 'rgba(230, 230, 230, 1)',
                                        height: rem(36),

                                        fontSize: '14px',
                                        background: '#000000 !important',
                                        border: 'none',
                                        position: 'relative',
                                      }
                                    }}
                                    variant="default"
                                    onClick={() => {
                                      setSelectedModel(model)
                                      setMode(MODE.SELECTED_MODEL)
                                    }}
                                  >
                                    Select
                                  </Button> : (
                                    <Flex direction={'column'}>
                                      <Text color="#84cc15" fz={12}>Training...</Text>
                                      <Progress radius="lg" size="lg" color="#84cc15" value={100} striped animate={true} w={'100%'} />
                                    </Flex>
                                  )
                              }
                            </Flex>
                          </Flex>
                        }
                        )
                    }

                  </Flex>
                </Flex>

                <Flex w={'100%'} direction={'column'} gap={12} mt={32}>
                  <Text color={'white'} fz={20}>Try pre-trained models</Text>
                  <Flex gap={42}>
                    <Flex gap={12}>
                      <Flex direction={'column'} gap={12}>
                        <Flex justify={'space-between'}>
                          <Flex direction={'column'} gap={2} >
                            <Text color="white">Beauty</Text>
                            <Text fz={12}>Generated images may only be used for demonstration purposes.</Text>
                          </Flex>

                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#84cc15 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={() => {
                              setSelectedModel({
                                "record_id": 18,
                                "version": "zuban/picshot:a7e6597154757dce2a78954af05482b4c9d2870135a3373566be48ca9ae88419",
                                "status": "completed",
                                "data": {
                                  "name": "Beauty",
                                  "images": [
                                    "https://cdn.picshot.ai/Beauty Album Title1.jpeg",
                                    "https://cdn.picshot.ai/Beauty Album Title2 2.jpeg",
                                    "https://cdn.picshot.ai/Beauty Album Title2.jpeg",
                                  ],
                                  "description": "photograph"
                                },
                                "__entity": "Model"
                              })
                              setMode(MODE.SELECTED_MODEL)
                            }}
                          >
                            Try
                          </Button>

                        </Flex>
                        <Flex gap={12}>
                          <Image width={'180px'} height={'180px'} src="https://cdn.picshot.ai/Beauty Album Title1.jpeg"></Image>
                          <Image width={'180px'} height={'180px'} src="https://cdn.picshot.ai/Beauty Album Title2 2.jpeg"></Image>
                          <Image width={'180px'} height={'180px'} src="https://cdn.picshot.ai/Beauty Album Title2.jpeg"></Image>
                        </Flex>
                      </Flex>

                    </Flex>

                    <Flex gap={12}>
                      <Flex direction={'column'} gap={12}>
                        <Flex justify={'space-between'}>
                          <Flex direction={'column'} gap={2} >
                            <Text color="white">Illustrations</Text>
                            <Text fz={12}>Generated images may only be used for demonstration purposes.</Text>
                          </Flex>

                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#84cc15 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={() => {
                              setSelectedModel({
                                "record_id": 16,
                                "version": "zuban/picshot:caa3c69d66ae422a74d5ce2b476180c70b1cef150e443c122be476414ee5077e",
                                "status": "completed",
                                "data": {
                                  "name": "Illustration",
                                  "images": [
                                    "https://cdn.picshot.ai/Illustrations1.png",
                                    "https://cdn.picshot.ai/Illustrations2.png",
                                    "https://cdn.picshot.ai/Illustrations3.png",

                                  ],
                                  "description": "illustration"
                                },
                                "__entity": "Model"
                              })
                              setMode(MODE.SELECTED_MODEL)
                            }}
                          >
                            Try
                          </Button>

                        </Flex>
                        <Flex gap={12}>
                          <Image width={'180px'} height={'180px'} src="https://cdn.picshot.ai/Illustrations1.png"></Image>
                          <Image width={'180px'} height={'180px'} src="https://cdn.picshot.ai/Illustrations2.png"></Image>
                          <Image width={'180px'} height={'180px'} src="https://cdn.picshot.ai/Illustrations3.png"></Image>
                        </Flex>
                      </Flex>

                    </Flex>
                  </Flex>

                </Flex>

              </Flex>
            )
          }
          {
            mode === MODE.SELECTED_MODEL && (
              <>
                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
                    <Text color={'white'} fz={20}>Model: {selectedModel?.data?.name}</Text>
                    <Flex justify={'space-between'}>
                      {(selectedModel?.data?.images || [])
                        .slice(0, 6)
                        .map((img, index) => {
                          return <Flex
                            key={index}
                            h="auto"
                            p={2}
                            w={'33.3%'}
                            onClick={() => {
                              setBacgroundSrc({
                                url: img
                              })
                            }}
                          >
                            <ImageObserved
                              width={'100%'}
                              height={'auto'}
                              src={img}
                            />
                          </Flex>
                        })
                      }
                    </Flex>
                    <Textarea
                      w="100%"
                      minRows={5}
                      size="sm"
                      label="Prompt"
                      value={modelParams.prompt}
                      description=""
                      placeholder="illustration of dog"
                      onChange={(e) => {
                        setModelParams({
                          ...modelParams,
                          prompt: e.currentTarget.value
                        })
                      }}
                    />

                    <Text color={'white'} fz={16} mt={12}>Choose aspect ratio</Text>
                    <Flex w={'100%'} gap={4} wrap={'wrap'}>
                      {
                        ['1:1', '16:9', '21:9', '2:3', '3:2', '4:5', '5:4', '9:16', '9:21'].map((side) => (
                          <Button
                            size="xs"
                            style={{
                              width: '80px',
                              border: '1px solid rgba(255, 255, 255, 1)',
                              color: 'rgba(255, 255, 255, 1)',
                              fontSize: '14px',
                            }}
                            color="rgba(255, 255, 255, 1)"
                            variant={modelParams.aspect_ratio === side ? "default" : 'outline'}
                            onClick={() => {
                              setModelParams({
                                ...modelParams,
                                aspect_ratio: side,
                              })
                            }}
                          >{side}</Button>
                        ))
                      }
                    </Flex>

                    <Text color={'white'} fz={16}>Choose training set streight</Text>
                    <Slider
                      size="sm"
                      color="#84cc15"
                      sx={{ maxWidth: 360 }}
                      mt={0}
                      min={0}
                      max={2}
                      step={0.1}
                      label={modelParams.lora_scale}
                      value={+modelParams.lora_scale}
                      onChangeEnd={(e) => {
                        setModelParams((prevState) => ({
                          ...prevState,
                          lora_scale: e.toFixed(2),
                        }))
                      }}
                    />
                    <Text color={'white'} fz={12}>Determines how strongly the training data should be applied. Good results between 0.5 and 1.4.</Text>
                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#84cc15 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'replicate',
                            type: 'customModelV1',

                            prompt: modelParams.prompt + `, ${selectedModel?.data.description || 'Photograph'} in style of PCSHOT`,
                            aspectRation: modelParams.aspect_ratio,
                            loraScale: modelParams.lora_scale,
                            credits: 4,
                            replicateVersion: selectedModel!.version,
                            srcset: []
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setLatestCustomModelRun({
                              srcset: [],
                              jobId: res.data.jobId
                            })
                          }


                        }}
                      >
                        {generateButtton(4)}
                      </Button>
                    </Flex>
                  </Flex>

                  {
                    latestCustomModelRun ? <Flex
                      w={'70%'}
                      gap={20}
                      direction={"column"}
                    >
                      {latestCustomModelRun.srcset.length > 0 ? (
                        <Flex
                          gap={20}
                        >
                          {latestCustomModelRun?.srcset.map((src, index) => (
                            <Flex direction={'column'}>
                              {renderMenu('1024x1024', src)}
                              <ImageObserved
                                width={'440px'}
                                height={'440px'}
                                dark={true}
                                src={src}
                              />
                            </Flex>
                          ))}
                        </Flex>
                      ) : <Flex w="100%" h="100%" p={32} align={'center'} justify={'center'} direction={'column'}>

                        <Text color="#84cc15" fz={12}>Will be ready in 2 minutes or less</Text>
                        <Progress radius="lg" size="lg" color="#84cc15" value={100} striped animate={true} w={'100%'} />
                      </Flex>}
                    </Flex> :
                      <Flex
                        gap={20}
                      >
                        {[1, 2, 3, 4].map((src, index) => (
                          <Flex direction={'column'}>
                            <ImageObserved
                              width={'440px'}
                              height={'440px'}
                              dark={true}
                              src={''}
                            />
                          </Flex>
                        ))}
                      </Flex>
                  }

                </Flex>
              </>
            )
          }
          {
            mode === MODE.ENLARGE && (
              <>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}
                    color="white"
                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} color="white">Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} w={'30%'} gap={12}>
                    <Text color={'white'} fz={20}>Choose the enlarge option</Text>

                    <Button
                      size="lg"
                      style={{
                        border: '1px solid rgba(255, 255, 255, 1)',
                        color: 'rgba(255, 255, 255, 1)',
                        fontSize: '14px',
                      }}
                      leftIcon={
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="8.61621" y="8.45679" width="15.0866" height="15.0866" stroke="white" />
                          <path d="M2.16003 24.0434V23.5434H1.16699C0.89085 23.5434 0.666992 23.3196 0.666992 23.0434V22.163H0.166992V20.4022H0.666992V18.6414H0.166992V16.8805H0.666992V15.1197H0.166992V13.3589H0.666992V11.598H0.166992V9.8372H0.666992V8.95679C0.666992 8.68065 0.89085 8.45679 1.16699 8.45679H2.16003V7.95679H4.14612V8.45679H6.1322V7.95679H8.11829V8.45679H8.61133V8.9622H9.11133V10.973H8.61133V12.9839H9.11133V14.9947H8.61133V17.0055H9.11133V19.0164H8.61133V21.0272H9.11133V23.038H8.61133V23.5434H8.11829V24.0434H6.1322V23.5434H4.14612V24.0434H2.16003Z" fill="#6FCF97" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                          <path d="M30.1427 24.0434V23.5434H31.1357C31.4119 23.5434 31.6357 23.3196 31.6357 23.0434V22.163H32.1357V20.4022H31.6357V18.6414H32.1357V16.8805H31.6357V15.1197H32.1357V13.3589H31.6357V11.598H32.1357V9.8372H31.6357V8.95679C31.6357 8.68065 31.4119 8.45679 31.1357 8.45679H30.1427V7.95679H28.1566V8.45679H26.1705V7.95679H24.1844V8.45679H23.6914V8.9622H23.1914V10.973H23.6914V12.9839H23.1914V14.9947H23.6914V17.0055H23.1914V19.0164H23.6914V21.0272H23.1914V23.038H23.6914V23.5434H24.1844V24.0434H26.1705V23.5434H28.1566V24.0434H30.1427Z" fill="#6FCF97" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                        </svg>

                      }
                      color="rgba(255, 255, 255, 1)"
                      variant={enlargeParams.left === 250 && enlargeParams.top === 0 ? "default" : 'outline'}
                      onClick={() => {
                        setEnlargeParams({
                          ...enlargeParams,
                          left: 250,
                          top: 0,
                        })
                      }}
                    >On the sides</Button>
                    <Button
                      size="lg"
                      style={{
                        border: '1px solid rgba(255, 255, 255, 1)',
                        color: 'rgba(255, 255, 255, 1)',

                        fontSize: '14px',
                      }}
                      leftIcon={
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="23.5273" y="8.4646" width="15.0866" height="15.0866" transform="rotate(90 23.5273 8.4646)" stroke="white" />
                          <path d="M7.9407 2.00891L8.4407 2.00891L8.4407 1.01587C8.4407 0.739726 8.66455 0.515868 8.9407 0.515868L9.82111 0.515869L9.82111 0.0158685L11.5819 0.0158686L11.5819 0.515869L13.3428 0.515869L13.3428 0.0158687L15.1036 0.0158688L15.1036 0.515869L16.8644 0.515869L16.8644 0.0158688L18.6253 0.0158689L18.6253 0.515869L20.3861 0.515869L20.3861 0.015869L22.1469 0.0158691L22.1469 0.515869L23.0273 0.515869C23.3035 0.515869 23.5273 0.739727 23.5273 1.01587L23.5273 2.00891L24.0273 2.00891L24.0273 3.995L23.5273 3.995L23.5273 5.98108L24.0273 5.98108L24.0273 7.96716L23.5273 7.96716L23.5273 8.46021L23.0219 8.46021L23.0219 8.96021L21.0111 8.9602L21.0111 8.4602L19.0003 8.4602L19.0003 8.9602L16.9894 8.9602L16.9894 8.4602L14.9786 8.4602L14.9786 8.9602L12.9678 8.9602L12.9678 8.4602L10.9569 8.4602L10.9569 8.9602L8.94611 8.9602L8.94611 8.4602L8.4407 8.4602L8.4407 7.96716L7.9407 7.96716L7.9407 5.98108L8.4407 5.98108L8.4407 3.99499L7.9407 3.99499L7.9407 2.00891Z" fill="#6FCF97" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                          <path d="M7.9407 29.9913L8.4407 29.9913L8.4407 30.9844C8.4407 31.2605 8.66455 31.4844 8.9407 31.4844L9.82111 31.4844L9.82111 31.9844L11.5819 31.9844L11.5819 31.4844L13.3428 31.4844L13.3428 31.9844L15.1036 31.9844L15.1036 31.4844L16.8644 31.4844L16.8644 31.9844L18.6253 31.9844L18.6253 31.4844L20.3861 31.4844L20.3861 31.9844L22.1469 31.9844L22.1469 31.4844L23.0273 31.4844C23.3035 31.4844 23.5273 31.2605 23.5273 30.9844L23.5273 29.9913L24.0273 29.9913L24.0273 28.0052L23.5273 28.0052L23.5273 26.0192L24.0273 26.0192L24.0273 24.0331L23.5273 24.0331L23.5273 23.54L23.0219 23.54L23.0219 23.04L21.0111 23.04L21.0111 23.54L19.0003 23.54L19.0003 23.04L16.9894 23.04L16.9894 23.54L14.9786 23.54L14.9786 23.04L12.9678 23.04L12.9678 23.54L10.9569 23.54L10.9569 23.04L8.94611 23.04L8.94611 23.54L8.4407 23.54L8.4407 24.0331L7.9407 24.0331L7.9407 26.0192L8.4407 26.0192L8.4407 28.0052L7.9407 28.0052L7.9407 29.9913Z" fill="#6FCF97" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                        </svg>
                      }
                      color="rgba(255, 255, 255, 1)"
                      variant={enlargeParams.left === 0 && enlargeParams.top === 250 ? "default" : 'outline'}
                      onClick={() => {
                        setEnlargeParams({
                          ...enlargeParams,
                          left: 0,
                          top: 250
                        })
                      }}
                    >Above and below</Button>
                    <Button
                      size="lg"
                      style={{
                        border: '1px solid rgba(255, 255, 255, 1)',
                        color: 'rgba(255, 255, 255, 1)',

                        fontSize: '14px',
                      }}
                      leftIcon={
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.833984" y="0.5" width="31" height="31" rx="0.5" fill="#6FCF97" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                          <rect x="8.7832" y="8.45654" width="15.0866" height="15.0866" fill="#333333" stroke="white" />
                        </svg>

                      }
                      color="rgba(255, 255, 255, 1)"
                      variant={enlargeParams.left === 250 && enlargeParams.top === 250 ? "default" : 'outline'}
                      onClick={() => {
                        setEnlargeParams({
                          ...enlargeParams,
                          left: 250,
                          top: 250,
                        })
                      }}>From all side</Button>
                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#84cc15 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'replicate',
                            type: 'enlarge',
                            outpaintUp: enlargeParams.top,
                            outpaintDown: enlargeParams.top,
                            outpaintLeft: enlargeParams.left,
                            outpaintRight: enlargeParams.left,
                            inputSrc: enlargeParams.url,
                            outputSrc: '',
                            size: 1,
                            credits: 5,
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setEnlargeParams({
                              top: 0,
                              left: 250,
                              url: '',
                            })
                            setMode(MODE.PIPELINES)
                          }
                        }}
                      >
                        {generateButtton(5)}
                      </Button>
                    </Flex>
                  </Flex>

                  <Flex>
                    <Text>Square</Text>
                  </Flex>
                  <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'}>
                    <ImageObserved
                      width={'600px'}
                      height={'600px'}

                      dark
                      src={enlargeParams.url}
                    />
                  </Flex>
                </Flex>
              </>
            )
          }
          {
            mode === MODE.RELIGHT && (
              <>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}
                    color="white"
                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} color="white">Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} w={'30%'} gap={12}>
                    <Text fz={20} color="white">Select the position of the light</Text>
                    {
                      Object.keys(RELIGHT_PROMPS).map((key) => (

                        <Button
                          size="lg"
                          style={{
                            border: '1px solid rgba(255, 255, 255, 1)',
                            color: 'rgba(255, 255, 255, 1)',
                            fontSize: '14px',
                          }}
                          color="rgba(255, 255, 255, 1)"
                          variant={relightParams.preset === key ? "default" : 'outline'}
                          onClick={() => {
                            setRelightParams({
                              ...relightParams,
                              preset: key,
                            })
                          }}>{RELIGHT_PROMPS[key]}
                        </Button>

                      ))
                    }

                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#84cc15 !important',
                            border: 'none',
                            position: 'relative',
                          },
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'comfy',
                            type: 'relightMask',
                            inputSrc: relightParams.url,
                            maskSrc: 'https://ugcr2.picshot.com/eu/Q3kYUdjhkdZf423LZf9kW_J2mCOo90zpK5NWeNlXHpp',
                            // @ts-ignore
                            prompt: RELIGHT_PROMPS[relightParams.preset],
                            negativePrompt: 'bad quality',
                            srcset: [],
                            credits: 5,
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setRelightParams({
                              url: '',
                              preset: 'sunny',
                              mask: 1,
                            })
                            setMode(MODE.PIPELINES)
                          }


                        }}
                      >
                        {generateButtton(5)}
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'} pos="relative">
                    <ImageObserved
                      width={'600px'}
                      height={'600px'}

                      dark
                      src={relightParams.url}

                    />
                    <Flex pos="absolute" top={0} left={0} w={'100%'} h="100%" justify={'space-between'} align={'center'} wrap={"wrap"}>
                      {
                        Array.from({ length: 9 }).map((_, idx) => (
                          <Flex
                            w={'33.3%'}
                            h="33.3%"
                            p={12}
                            opacity={0.2}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setRelightParams({
                                ...relightParams,
                                mask: idx + 1,
                              })
                            }}>
                            <Flex w={'100%'} h="100%" style={{ background: relightParams.mask === idx + 1 ? '#ededed' : 'white' }}></Flex>
                          </Flex>
                        ))
                      }


                    </Flex>
                  </Flex>
                </Flex>
              </>
            )
          }
          {
            mode === MODE.UPSCALE && (
              <>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}
                    color="white"
                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} color="white">Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} w={'30%'} gap={12}>

                    <Text color={'white'} fz={20}>Upscale image</Text>
                    <Flex gap={12} mt={32}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#84cc15 !important',
                            border: 'none',
                            position: 'relative',
                          },
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'replicate',
                            type: 'upscale',
                            inputSrc: imageSrc!.url,
                            outputSrc: '',
                            credits: 5,
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setImageSrc(null)
                            setMode(MODE.PIPELINES)
                          }
                        }}
                      >
                        {generateButtton(5)}
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'}>
                    <ImageObserved
                      width={'600px'}
                      height={'600px'}
                      dark
                      src={imageSrc!.url}
                    />
                  </Flex>
                </Flex>
              </>
            )
          }


          <Flex
            direction={'column'}
            w="100%"
            gap={8}
            style={{
              opacity: mode === MODE.PIPELINES ? 1 : 0,
              visibility: mode === MODE.PIPELINES ? 'visible' : 'hidden',
              height: mode === MODE.PIPELINES ? undefined : '0',
              overflow: mode === MODE.PIPELINES ? 'visible' : 'hidden',
            }}>
            <Flex justify={'flex-end'}>
              <Text
                style={{ cursor: 'pointer' }}
                color="white"
                fz={14}
                onClick={() => {
                  setBig(!big)
                }}>
                {
                  big ? 'View small preview' : 'View big preview'
                }
              </Text>
            </Flex>

            {
              pipelines
                // .slice(0, 60)
                .map((pipeline, index) => (
                  <Flex direction={'column'} key={pipeline.jobId} >
                    <PipelineItem
                      big={big}
                      dark={index % 2 === 0}
                      pipeline={pipeline}
                      update={(pipeline: PipelineData) => {
                        console.log(pipelines)
                        console.log(pipeline)
                        setPipelines(pipelines.map((p, i) => i === index ? pipeline : p))

                        console.log(pipeline, 'ended')
                        if (latestRunId?.jobId === pipeline.jobId) {
                          setLatestRunId({
                            jobId: pipeline.jobId,
                            url: pipeline.params.srcset[0]
                          })
                        }

                        if (pipeline.jobId === latestRunIds.find(itm => itm.jobId === pipeline.jobId)?.jobId) {
                          setLatestRunIds(latestRunIds.map(itm => itm.jobId === pipeline.jobId ? {
                            jobId: pipeline.jobId,
                            url: pipeline.params.srcset[0]
                          } : itm))
                        }

                        if (pipeline.params.type === 'customModelV1') {
                          setLatestCustomModelRun({
                            jobId: pipeline.jobId,
                            srcset: pipeline.params.srcset
                          })
                        }
                      }}
                      onUpscale={(image) => {
                        setImageSrc({
                          url: image,
                        });
                        setMode(MODE.UPSCALE)
                      }}
                      pickImage={async (image) => {
                        const res = await runCreatePipeline({
                          provider: 'stability',
                          type: 'genVideo',
                          inputSrc: image,
                          outputSrc: '',
                          credits: 5,
                        });
                        if (res.error) {
                          NOTIFICATION_SERVICE.errorNotification(
                            "Not enough credits"
                          );
                          setBillingModalOpen(true)
                        } else {
                          setPipelines([res.data, ...pipelines,])
                          // NOTIFICATION_SERVICE.successNotification('Generation started')
                        }
                        // setImageSrc({
                        //   panoramaImage: '',
                        //   url: image,
                        // });
                        // setMode(MODE.SELECT_MODE);
                      }}
                      relightGradient={(image) => {
                        // setRelightParams({
                        //   url: image,
                        //   preset: 'sunny',
                        // });
                        // // setImageSrc(image);
                        // setMode(MODE.RELIGHT);
                        // setPrompt('Concrete building, abstract, soft studio light, masterpiece, (masterpiece:1.2), (best quality, highest quality)');
                        // setNegativePrompt('bad quality, bad picture');
                      }}
                      relightMask={(image) => {
                        console.log(image)
                        setRelightParams({
                          url: image,
                          preset: 'sunny',
                          mask: 1,
                        });
                        // setImageSrc(image);
                        setMode(MODE.RELIGHT);
                      }}
                      onEnlarge={(image) => {
                        setEnlargeParams({
                          ...enlargeParams,
                          url: image
                        })
                        setMode(MODE.ENLARGE)
                      }}

                      create={async (pipeline: PipelineParameters) => {
                        const res = await runCreatePipeline(pipeline);
                        if (res.error) {
                          NOTIFICATION_SERVICE.errorNotification(
                            "Not enough credits"
                          );
                          setBillingModalOpen(true)
                        } else {
                          setPipelines([res.data, ...pipelines,])
                          // NOTIFICATION_SERVICE.successNotification('Generation started')
                        }

                      }}
                    />
                  </Flex>
                ))
            }
          </Flex>
        </Flex>
        {
          (pipelines.length > 0 || models.length > 0) && (<Flex w={'200px'} bg="#000000" style={{ borderRadius: '8px', overflow: 'scroll' }} p={'0.8rem'} direction={'column'} gap={12}>
            <Text fz={18} color="white" style={{ fontFamily: 'Lora' }}>Queue</Text>

            {
              pipelines.map(itm => {
                return (
                  <Flex
                    key={itm.jobId}
                    style={{
                      borderRadius: '4px',
                      border: '1px solid #333',
                    }} p={'1rem'} direction={'column'} gap={2} onClick={() => {
                      setMode(MODE.PIPELINES)
                    }}>
                    <Text fz={12} color="white" style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>RUN {itm.jobId}</Text>

                    <Text fz={10} color="tile" style={{ overflow: 'hidden' }}>Created <RelativeTime time={itm.createdAt} /></Text>
                    {/* <Text fz={10} color="tile" style={{ overflow: 'hidden' }}>{itm.jobId}</Text> */}
                    <Text fz={12} color="white">
                      {itm.status === 'pending' ? 'Running' : ''}
                      {itm.status === 'ending' ? 'Finalizing' : ''}
                      {/* {itm.status === 'completed' ? 'Completed' : ''} */}
                      {itm.status === 'failed' ? 'Failed' : ''}
                    </Text>
                    {
                      itm.status !== 'completed' && itm.status !== 'failed' && (<Progress radius="lg" size="lg" color="#84cc15" value={100} striped animate={true} w={'100%'} />)
                    }
                    {
                      itm.status === 'completed' ?

                        <Button
                          h="23.8px"
                          bg="#242b1e"
                          c="#b4e45f"
                          fw={700}
                          variant="light"
                          color="#b4e45f"
                          fz={8}
                          onClick={() => setMode(MODE.PIPELINES)}>View generation</Button>


                        : null
                    }
                  </Flex>
                )
              }
              )
            }
          </Flex>)
        }

      </Flex>
    </AppShell >
  );
};

export const SiteListLayout = observer(Element);
